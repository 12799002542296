import React from "react";

const Login = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row ">
              <div
                className="col-md-4 offset-md-4 rounded mt-5 mb-5 p-5 "
                style={{ border: "1px solid black" }}
              >
                <h2>Sign-in</h2>
                <form>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      Password
                    </label>{" "}
                    <span className="float-end">Forgot your password?</span>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>

                  <button class="btn btn-dark w-100" type="submit">
                    Sign in
                  </button>
                  <div class="mb-3 mt-3 form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <label class="form-check-label" for="exampleCheck1">
                      Keep me signed in.{" "}
                    </label>{" "}
                  </div>
                  <button class="btn btn-outline-dark w-100" type="button">
                    <a
                      href="/Register"
                      className="text-info text-decoration-none"
                    >
                      Create your zappos account
                    </a>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
