import React from "react";
import "./Navbar.css";
const Navbar = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          {/* sale */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Sale</span>
            </button>
            <div
              class="dropdown-menu ms-4 mt-4 "
              style={{
                width: "85vw",
                position: "relative",
                borderRadius: "10px",
                float: "left",
              }}
            >
              <div className="container-fluid m-2">
                <div className="row">
                  <div className="col-md-2 ">
                    <div className="items">
                      <li>
                        <h5>Women's Sale</h5>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          All Women's Sale
                        </a>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Sneakers & Athletic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Apparel $100 & Under
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shoes $50 & Under
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="items">
                      <li>
                        <h5>Men's Sale</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Men's Sale
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sneakers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shoes $50 & Under
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Apparel $100 & Under
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Kids' Sale</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Kids' Sale
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sneakers & Athletic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Apparel $50 & Under
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shoes $50 & Under
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Brands on Sale</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hoka
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          SOREL
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Native Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          New Balance
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          LEVI’S®
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Brooks
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hey Dude
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Crocs
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          New Balance
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          ASICS
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Steals & Deals</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sweaters $50 and Under
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Coats $100 and Under
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Slippers for the Family
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Pajamas for the Family
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Best-selling Jewelry
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Designer Bags
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Deals Starting at 49% Off
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sneakers Sale
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shop All Sale
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <h5>
                      <a href="/">
                        <i
                          class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </h5>

                    <div className="row pt-3 p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <div>
                          <img
                            className="img-fluid "
                            src={require("../img/sale5.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-0 m-0">
                        <div>
                          {" "}
                          <img
                            className="img-fluid "
                            src={require("../img/sale1.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* women */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Women</span>
            </button>
            <div
              class="dropdown-menu ms-5 mt-4"
              style={{
                width: "85vw",
                position: "relative",
                borderRadius: "10px",
                float: "left",
              }}
            >
              <div className="container-fluid m-2">
                <div className="row">
                  <div className="col-md-2 ">
                    <div className="items">
                      <li>
                        <h5>Shoes</h5>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          All Women's Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Sneakers & Athletic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots & Booties
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sandals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Heels
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Flats
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Clogs
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Mules
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Safety Sneakers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Duty Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Loafers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Slippers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Adaptive
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Comfort
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="items">
                      <li>
                        <h5>Clothing</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Women's Clothing
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Dresses
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shirts & Tops
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Coats & Outerwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Jeans & Denim
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sweaters
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shorts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Jumpsuits & Rompers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Skirts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Pants
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Duty Apparel
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Plus Size
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Activewear
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Accessories & More</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Women's Accessories
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Bags & Handbags
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sunglasses
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Hats
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Scarves
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Tech Accessories
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Gloves
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Belts
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Shop By</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Womens
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Bestsellers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          New Arrivals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Style Room: Women's Fashion
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Women's Winter Essentials
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The UGG® Guide
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Merrell Guide
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Sale
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Trending Brands</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          La Canadienne
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Vionic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Gabor
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Michael Kors Eyewear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          UGG®
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Gift Cards
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <h5>
                      <a href="/">
                        <i
                          class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </h5>

                    <div className="row pt-3 p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <div>
                          <img
                            className="img-fluid "
                            src={require("../img/s3.jpg")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-0 m-0">
                        <div>
                          {" "}
                          <img
                            className="img-fluid "
                            src={require("../img/sale1.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* men */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Men</span>
            </button>
            <div
              class="dropdown-menu ms-5 mt-4"
              style={{
                width: "85vw",
                position: "relative",
                borderRadius: "10px",
                float: "left",
              }}
            >
              <div className="container-fluid m-2">
                <div className="row">
                  <div className="col-md-2 ">
                    <div className="items">
                      <li>
                        <h5>Shoes</h5>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          All Men's Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Sneakers & Athletic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Loafers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Oxfords
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sandals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Slippers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hiking
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Safety Sneakers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Duty Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Adaptive
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Wide
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="items">
                      <li>
                        <h5>Clothing</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Men's Clothing
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Shirts & Tops
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Coats & Outerwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Jeans
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hoodies & Sweatshirts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shorts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Big & Tall
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Activewear
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Work & Duty Apparel
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Accessories & More</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Men's Accessories
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hats
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Bags
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Belts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Watches
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sunglasses & Eyewear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Wallets
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Gloves
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Shop By</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Mens
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Bestsellers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          New Arrivals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Style Room: Men's Fashion
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sale
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Merrell Guide
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Trending Brands</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Baffin
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Naot
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          ASICS
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Hoka
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Levi's Mens
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Brooks
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Dr. Martens
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Kamik
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Baffin
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Gift Cards
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <h5>
                      <a href="/">
                        <i
                          class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </h5>

                    <div className="row pt-3 p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <div>
                          <img
                            className="img-fluid "
                            src={require("../img/sale1.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-0 m-0">
                        <div>
                          {" "}
                          <img
                            className="img-fluid "
                            src={require("../img/sale5.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* kids */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Kids</span>
            </button>
            <div
              class="dropdown-menu ms-5 mt-4"
              style={{
                width: "85vw",
                position: "relative",
                borderRadius: "10px",
                float: "left",
              }}
            >
              <div className="container-fluid m-2">
                <div className="row">
                  <div className="col-md-2 ">
                    <div className="items">
                      <li>
                        <h5>Boys</h5>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          All Boys
                        </a>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Sneakers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shirts & Tops
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Coats & Jackets
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Pants
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Jeans
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shorts
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sandals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Swimwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Pajamas
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Adaptive
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="items">
                      <li>
                        <h5>Girls</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Girls
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Sneakers
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Boots
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Dresses
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Coats & Jackets
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shirts & Tops
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Pants
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Jeans
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Sandals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Swimwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Pajamas
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Adaptive
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Accessories & More</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All Kids' Accessories
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Backpacks
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Hats
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Toys & Games
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Shop By Size</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Big Kid (7-12 yrs.)
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Little Kid (4-7 yrs.)
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Toddler (9 mos.-4 yrs.)
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Infant (0-9 mos.)
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Shoe Size Conversion Chart
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          At Home Shoe Sizer
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>New & Trending</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          All New Arrivals
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Character Favorites
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Kids' Athletic
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Juniors & Teen
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Easy On Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Sports Cleats
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Kids' Sale
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Trending Brands
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Smartwool
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Kamik Footwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Baffin
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Polo Ralph Lauren
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          UGG®
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <h5>
                      <a href="/">
                        <i
                          class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </h5>

                    <div className="row pt-3 p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <div>
                          <img
                            className="img-fluid "
                            src={require("../img/sale6.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-0 m-0">
                        <div>
                          {" "}
                          <img
                            className="img-fluid "
                            src={require("../img/sale1.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* collections */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Collections</span>
            </button>
            <div
              class="dropdown-menu ms-5 mt-4"
              style={{
                width: "85vw",
                position: "relative",
                borderRadius: "10px",
                float: "left",
              }}
            >
              <div className="container-fluid m-2">
                <div className="row">
                  <div className="col ">
                    <div className="items">
                      <li>
                        <h5>Featured Shops</h5>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Adaptive
                        </a>
                      </li>
                      <li>
                        <a href="/Sale" className="list_items">
                          Conscious Clothing & Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Outdoor Shop
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Running Shop
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Wedding Shop
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col">
                    <div className="items">
                      <li>
                        <h5>The Style Room</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Wearing on Repeat
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Sportscore ft. New Balance
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Best in Bags
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Stylist-Approved Footwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Moody Romantic
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Color of the Moment: Red
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Men's Wardrobe on Repeat
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Men's Footwear Favorites
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Men's New Balance
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Trending Brands</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Kamik Footwear
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          VIONIC
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Michael Kors Eyewear
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Steve Madden
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          SOREL
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Chaco
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Madewell
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Crocs
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The North Face
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Birkenstock
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          UGG®
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Michael Kors
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Baffin
                        </a>
                      </li>
                    </div>
                  </div>
                  <div className="col">
                    {" "}
                    <div className="items">
                      <li>
                        <h5>Get Inspired</h5>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Travel Essentials
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Upcoming Releases
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The UGG® Guide
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          The Merrell Guide
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          You're a Doll: Iconic Pink Styles
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Our Top 10 Running Shoes
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Zappos at Work
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Learn More
                        </a>
                      </li>

                      <li>
                        <a href="/" className="list_items">
                          Amazon Employees
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          ZAW Frequently Asked Questions
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Work & Duty Shop
                        </a>
                      </li>
                      <li>
                        <a href="/" className="list_items">
                          Gift Cards
                        </a>
                      </li>
                    </div>
                  </div>

                  <div className="col">
                    <h5>
                      <a href="/">
                        <i
                          class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                          style={{ float: "right" }}
                        ></i>
                      </a>
                    </h5>

                    <div className="row pt-3 p-0 m-0">
                      <div className="col-12 p-0 m-0">
                        <div>
                          <img
                            className="img-fluid "
                            src={require("../img/a4.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 p-0 m-0">
                        <div>
                          {" "}
                          <img
                            className="img-fluid "
                            src={require("../img/a5.png")}
                            style={{ backgroundColor: "#f5f5f5" }}
                            alt=""
                          />{" "}
                          <div className="p-0 m-0">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-dark w-100 rounded-0 fs-5 fw-bold"
                            >
                              Shop Boots On Sale
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* brands */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">Brands</span>
            </button>
            <div
              class="dropdown-menu ms-lg-5 mt-4"
              style={{
                width: "52vw",
                position: "relative",
                borderRadius: "10px",
                alignContent: "center",
              }}
            >
              <div className="container-fluid m-2">
                <h5>
                  <a href="/">
                    <i
                      class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                      style={{ float: "right" }}
                    ></i>
                  </a>
                </h5>

                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="items">
                      <h5>Brand Index</h5>

                      <a href="/Sale" className="list_items pe-lg-1">
                        #
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        A
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        B
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        C
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        D
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        E
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        F
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        G
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        H
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        I
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        J
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        K
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        L
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        M
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        N
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        O
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        P
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        Q
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        R
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        S
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        T
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        U
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        V
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        W
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        X
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        Y
                      </a>
                      <a href="/Sale" className="list_items  p-lg-1">
                        Z
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row center">
                  <div className="col">
                    <hr></hr>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="items">
                      <li>
                        <h5> Featured Brands </h5>
                      </li>
                      <li>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands1.jpg")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands2.jpg")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands3.jpg")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands5.jpg")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands6.png")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands7.jpg")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands8.png")}
                            alt=""
                          />
                        </a>
                        <a href="/">
                          <img
                            className="img-fluid p-2"
                            src={require("../img/brands9.png")}
                            alt=""
                          />
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* new */}

          <div class="btn-group dropdown-center">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              style={{ border: "none" }}
            >
              <span class="item">New</span>
            </button>
            <div
              class="dropdown-menu ms-lg-5 mt-4"
              style={{
                width: "20vw",
                position: "relative",
                borderRadius: "10px",
                alignContent: "center",
              }}
            >
              <div className="container-fluid m-2">
                <h5>
                  <a href="/">
                    <i
                      class="bi bi-x-lg pe-3 pt-0 text-black ps-3 pb-3"
                      style={{ float: "right" }}
                    ></i>
                  </a>
                </h5>

                <div className="row">
                  <div className="col-lg-12 ">
                    <div className="items">
                      <li>
                        <a
                          href="/Sale"
                          className="text-decoration-none text-black"
                        >
                          new collection
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* terms and help */}

          <div class="btn-group float-end">
            <button
              type="button"
              class="btn btn dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="item">Tools & Help</span>
            </button>
            <div class="dropdown-menu m-2" style={{ borderRadius: "10px" }}>
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-lg-12 col-md-auto">
                    <ul className="items">
                      <h5>
                        <a href="/" className="list_items">
                          Return
                        </a>
                      </h5>
                      <h5>
                        <a href="/" className="list_items">
                          Get Help
                        </a>
                      </h5>
                      <h5>
                        <a href="/" className="list_items">
                          FAQs
                        </a>
                      </h5>
                      <h5>
                        <a href="/" className="list_items">
                          feedback
                        </a>
                      </h5>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {/* <div
          style={{
            height: "0.8px",
            backgroundColor: "gray",
            margin: "8px",
            opacity: "0.2",
          }}
        ></div> */}
      </section>
    </>
  );
};

export default Navbar;
