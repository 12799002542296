import React from "react";

const Help = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <p>return policy</p>
          <p>return policy</p>
          <p>FAQs</p>
          <p>feedback</p>
        </div>
      </div>
    </>
  );
};

export default Help;
