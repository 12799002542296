import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <>
      {/* <section>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#210ef4", paddingTop: "8px" }}
        >
          <div className="container-lg pb-4">
            <span
              style={{
                fontSize: "20px",
                color: "#ffffff",
                fontWeight: "600",
                paddingTop: "5px",
              }}
            >
              Make Zappos (Emails) Your New BFF!
            </span>
            <form className="d-flex flex-row justify-content-start align-items-start">
              <div className="pb-2 pt-2">
                <label class=" text-white">Email</label>
                <br></br>
                <input
                  type="email"
                  placeholder="Email Address"
                  style={{
                    background: "#210ef4",
                    border: "none",
                    borderBottom: "1px solid white",
                    paddingTop: "3px",
                    paddingBottom: "6px",
                    width: "250px",
                    color: "white",
                  }}
                />
                <br></br>
              </div>
              <button
                type="button"
                class="btn btn-primary btn-lg  mt-4 mx-5 rounded-pill border border-white "
                style={{ background: "transparent" }}
              >
                <i
                  class="bi bi-envelope-open p-2 opacity-75"
                  style={{ fontSize: "16px" }}
                ></i>
                <span style={{ fontSize: "15px", fontWeight: "700" }}>
                  Subscribe to Zappos Emails
                </span>
              </button>
            </form>
          </div>
        </div>
      </section> */}

      <section className="footer-2 ">
        <div className="container-fluid pt-3 bg-black">
          <div className="container-lg FontFamily">
            <div className="row ">
              <div className="col-xl-3  col-lg-3 col-md-4 col-sm-6">
                <div className="list group list-group-none">
                  <li className="list-group-item">
                    <h4 className="text-white ">Explore Zappos</h4>
                  </li>
                  <li className="text-white list-group-item p-1 ">Brands</li>
                  <li className="text-white list-group-item p-1 p-1 ">
                    Clothing
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    The Style Room
                  </li>
                  <li className="text-white list-group-item p-1  ">Eyewear</li>
                  <li className="text-white list-group-item p-1  ">
                    New Arrivals
                  </li>
                  <li className="text-white list-group-item p-1  ">Running</li>
                  <li className="text-white list-group-item p-1  ">Jackets</li>
                  <li className="text-white list-group-item p-1  ">Shoes</li>
                  <li className="text-white list-group-item p-1  ">Watches</li>
                  <li className="text-white list-group-item p-1  ">
                    Zappos Adaptive
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    All Departments
                  </li>
                </div>
              </div>

              <div className="col-xl-3  col-lg-3 col-md-4 col-sm-6">
                <div className="list group mt-2 list-group-none">
                  <li className="list-group-item">
                    <h4 className="text-white">Customer Service</h4>
                  </li>
                  <li className="text-white list-group-item p-1  ">FAQs</li>
                  <li className="text-white list-group-item p-1  ">
                    Contact Info
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    ¿Ayuda en español?
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Shipping And Returns Policy
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    About Proposition 65
                  </li>

                  <li className="text-white list-group-item p-1  ">
                    <h5>Fit Info</h5>
                  </li>
                  <li className="text-white list-group-item p-1 ">
                    Measurement Guide
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Size Conversion Chart
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Measure Your Bra Size
                  </li>
                </div>
              </div>

              <div className="col-xl-3  col-lg-3 col-md-4 col-sm-6">
                <div className="list group list-group-none">
                  <li className="list-group-item">
                    <h4 className="text-white">About Zappos</h4>
                  </li>
                  <li className="text-white list-group-item p-1  ">About</li>
                  <li className="text-white list-group-item p-1  ">
                    Zappos ONE
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Zappos for Good
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Zappos at Work
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Get the Zappos Mobile App
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Amazon Prime Benefits
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Zappos VIP Benefits
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Powered by Zappos
                  </li>
                  <li className="text-white list-group-item p-1  ">Coupons</li>
                  <li className="text-white list-group-item p-1  ">
                    Accessibility Statement
                  </li>

                  <li className="text-white list-group-item p-1  ">
                    <h5>Resources</h5>
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Associates Program
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Influencer Program
                  </li>
                  <li className="text-white list-group-item p-1  ">Jobs</li>
                  <li className="text-white list-group-item p-1  ">
                    Press Kit & Brand Inquiries
                  </li>
                  <li className="text-white list-group-item p-1  ">Site Map</li>
                </div>
              </div>

              <div className="col-xl-3  col-lg-3 col-md-4 col-sm-6">
                <div className="list group list-group-none">
                  <li className="list-group-item">
                    <h4 className="text-white">Your Voice Matters</h4>
                  </li>
                  <p className="text-white pt-1">
                    We'd love to learn more about your shopping experiences on
                    Zappos.com and how we can improve!
                  </p>
                  <button type="button" class="btn btn-dark ">
                    <i class="far fa-comments"></i> Take Survey
                  </button>
                  <h5 className="text-white mt-5">Connect With Us</h5>
                  <a
                    href="https://www.facebook.com/"
                    className="text-decoration-none fs-2 text-white p-1"
                  >
                    <i class="fa-brands fa-facebook" id="socialIcon"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    className="text-decoration-none fs-2 text-white p-1"
                  >
                    <i class="fa-brands fa-instagram" id="socialIcon"></i>
                  </a>
                  <a
                    href="https://twitter.com/"
                    className="text-decoration-none fs-2 text-white p-1"
                  >
                    <i class="fa-brands fa-twitter" id="socialIcon"></i>
                  </a>
                  <a
                    href="https://in.pinterest.com/login/"
                    className="text-decoration-none fs-2 text-white p-1"
                  >
                    <i class="fa-brands fa-pinterest" id="socialIcon"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/"
                    className="text-decoration-none fs-2 text-white p-1"
                  >
                    <i class="fa-brands fa-youtube" id="socialIcon"></i>
                  </a>
                </div>
              </div>
              <hr style={{ color: "white" }}></hr>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-3">
        <div className="container-fluid pb-5 pt-5 bg-black d-none d-sm-block">
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-10">
                <ul className="d-flex" style={{ listStyleType: "none" }}>
                  <li>
                    <a href="/" className="text-white text-decoration-none p-2">
                      Terms of Use
                    </a>
                  </li>

                  <li>
                    <a href="/" className="text-white text-decoration-none p-2">
                      | Privacy Policy
                    </a>
                  </li>

                  <li>
                    <a href="/" className="text-white text-decoration-none p-2">
                      | Fur Policy
                    </a>
                  </li>

                  <li>
                    <a href="/" className="text-white text-decoration-none p-2">
                      | Interest-Based Ads
                    </a>
                  </li>

                  <li>
                    <a href="/" className="text-white text-decoration-none p-2">
                      | 24/7 Customer Service (800) 927-7671
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="container mt-3">
              <p className="text-white">
                © 2009–2023 - Zappos.com LLC or its affiliates
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid pb-5 pt-3 bg-black d-sm-none d-block">
          <div className="container-lg">
            <div className="row ">
              <div className="  col-lg-6 ">
                <div className="list group list-group-none">
                  <li className="text-white list-group-item p-1 p-1 ">
                    Terms of Use
                  </li>
                  <li className="text-white list-group-item p-1 p-1 ">
                    Privacy Policy
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Fur Policy
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    Interest-Based Ads
                  </li>
                  <li className="text-white list-group-item p-1  ">
                    24/7 Customer Service (800) 927-7671
                  </li>
                </div>
              </div>
            </div>

            <div className="container mt-3">
              <h6 className="text-white ">
                © 2009–2023 - Zappos.com LLC or its affiliates
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
