import React from "react";

const SaleCategory = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container"></div>
      </div>
    </>
  );
};

export default SaleCategory;
