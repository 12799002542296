import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TopNav from "./TopNav";
import CenterNav from "./CenterNav";
import Home_ from "./Home_";
import Error from "./Error";
import Sale from "./Sale";
import Women from "./Women";
import Men from "./Men";
import Kids from "./Kids";
import Collections from "./Collections";
import Brands from "./Brands";
import New from "./New";
import Clothings from "./Clothings";
import Help from "./Help";
import Login from "./Login";
import Register from "./Register";
import SaleCategory from "./SaleCategory";

const Routing = () => {
  return (
    <>
      <React.Fragment>
        <header>
          <TopNav />
          <CenterNav />
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route to path="/" element={<Home_ />} />
            <Route to path="*" element={<Error />} />
            <Route to path="/sale" element={<Sale />} />
            <Route to path="/women" element={<Women />} />
            <Route to path="/men" element={<Men />} />
            <Route to path="/kids" element={<Kids />} />
            <Route to path="/collections" element={<Collections />} />
            <Route to path="/brands" element={<Brands />} />
            <Route to path="/new" element={<New />} />
            <Route to path="/clothings" element={<Clothings />} />
            <Route to path="/help" element={<Help />} />
            <Route to path="/login" element={<Login />} />
            <Route to path="/register" element={<Register />} />
            <Route to path="/salecategory" element={<SaleCategory />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </React.Fragment>
    </>
  );
};

export default Routing;
