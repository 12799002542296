import React from "react";

const Men = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="container p-4 " style={{ height: "100%" }}>
            <button className="bg-light rounded-pill mb-3">
              <a className="text-decoration-none text-black" href="/">
                <i class="bi bi-arrow-left"></i>
              </a>
            </button>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a6.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a3.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a4.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a5.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a6.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a1.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a2.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a3.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "250px",
                      width: "300px",
                      padding: "7px",
                    }}
                  >
                    <img
                      className="img-fluid pb-2 text-center"
                      src={require("../img/a4.png")}
                      alt=""
                    />
                  </div>

                  <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                    Hunter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Men;
