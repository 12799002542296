import React from "react";

const Register = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row ">
              <div
                className="col-md-4 offset-md-4 rounded mt-5 mb-5 p-5 "
                style={{ border: "1px solid black" }}
              >
                <h2>Create Account</h2>
                <form>
                  <div class="mb-3">
                    <label for="name1" class="form-label fw-bold">
                      Your Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="examplename1"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label fw-bold">
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="exampleInputPassword1"
                      class="form-label fw-bold"
                      placeholder="At least 6 characters"
                    >
                      Password
                    </label>

                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder="At least 6 characters"
                    />
                    <p style={{ fontSize: "12px" }}>
                      Passwords must be at least 6 characters.
                    </p>
                  </div>
                  <div class="mb-3">
                    <label
                      for="exampleInputPassword1"
                      class="form-label fw-bold"
                      placeholder="At least 6 characters"
                    >
                      Re-enter Password
                    </label>

                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>

                  <button class="btn btn-dark w-100 fw-bold" type="submit">
                    Create your zappos account
                  </button>

                  <div className="pt-3 pb-3 ">
                    <p style={{ fontSize: "12px" }}>
                      Don't have an email address or need additional help? Don't
                      worry! Give us a call at (800) 927-7671
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      Registering means you agree to the Zappos terms of use and
                      privacy policy
                    </p>
                  </div>
                  <div className="pt-3 pb-3  ">
                    <p style={{ fontSize: "12px" }}>
                      Already have an account?{" "}
                      <a href="/Login" className="text-black ">
                        Sign in
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
