import React from "react";
import First_slide from "./First_slide";
import Second_slide from "./Second_slide";
import Third_slide from "./Third_slide";
import "./Home.css";
import Auto from "./Auto";

const Home_ = () => {
  return (
    <>
      {/* <Auto /> */}

      <section>
        <div className="container-fluid center img_card">
          <img
            className="img-fluid"
            src={require("../img/below_nav_image.gif")}
            alt=""
          />
        </div>

        <div className="container-fluid center mt-lg-4 image1">
          <img
            className="img-fluid"
            src={require("../img/home_image.gif")}
            alt=""
          />
        </div>
        <div className="container-fluid  p-0 image2">
          <img
            className="img-fluid"
            src={require("../img/home_image2.jpg")}
            alt=""
          />
        </div>
      </section>

      <section>
        <div className="container-fluid mb-5">
          <div className="container__content">
            <div className="center">
              <h3 style={{ fontWeight: "700" }}>
                {" Birkenstock:Serene Slippers "}
              </h3>
              <p>
                {
                  "Keep your feet comfy and warm with plush favourites you'll adore. "
                }
              </p>
              <button
                className=""
                style={{ border: "none", background: "none" }}
              >
                <a href="/" style={{ color: "black" }}>
                  {" Shop Birekenstock"}
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="mx-5 mb-5">
            <h3 style={{ fontWeight: "700" }}>Shop Popular Categories</h3>
          </div>
        </div>
      </section>

      <section>
        <First_slide />
      </section>

      <section>
        <div className="container-fluid pb-5 pt-3">
          <div className="container lg">
            <div className="row">
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    src={require("../img/clearance.jpeg")}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5>$100 & Under:Unveil the Delights</h5>
                  <p>it's a mystery these amazing deals are still here</p>
                  <a href="/" className="text-dark">
                    {" "}
                    Shop &100 Under Clearance
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    src={require("../img/clearance2.jpeg")}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5>"They Were Only $50 & Under!"</h5>
                  <p>Seize these prices before the word spreads.</p>
                  <a href="/" className="text-dark">
                    {"  Shop $50 & Under Clearance"}
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    src={require("../img/clearance3.jpeg")}
                    alt=""
                  />
                </div>
                <div className="text-center">
                  <h5>Footwear Savings: Found at Last</h5>
                  <p>These are the hidden gems you've been looking for.</p>
                  <a href="/" className="text-dark">
                    {" Shop Shoes Clearance"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pb-5 pt-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-2">
                <img
                  className="img-fluid"
                  height={"1090px"}
                  width={"1000px"}
                  src={require("../img/new arrival.png")}
                  alt=""
                />
              </div>
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  width={"1000px"}
                  src={require("../img/boy_img.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="mx-5 mb-5">
            <h3 style={{ fontWeight: "700" }}>
              Styles You'll Love From Hot Brands
            </h3>
          </div>
        </div>
      </section>

      <section>
        <Second_slide />
      </section>

      <section>
        <div className="container-fluid pt-3 pb-4">
          <div className="cont">
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <img
                  className="img-fluid"
                  src={require("../img/girl_img.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-4 col-md-6 d-flex justify-content-center align-items-start flex-column">
                <span style={{ fontSize: "40px", fontWeight: "400" }}>
                  THE STYLE ROOM
                </span>{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    paddingTop: "3px",
                  }}
                >
                  Mindful MInimalism
                </span>{" "}
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    paddingTop: "3px",
                  }}
                >
                  {" "}
                  Celebrate simplicity by introducing versatile pieces to your
                  wardrobe that you can wear again and again.
                </span>{" "}
                <a href="/" style={{ color: "#000000", paddingTop: "15px" }}>
                  Shop Mindful Minimalism
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="mx-5 mt-5">
            <h3 style={{ fontWeight: "700" }}>Popular Items</h3>
          </div>
        </div>
      </section>

      <section>
        <Third_slide />
      </section>

      <section>
        <div className="container-fluid pb-5 pt-3">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    height={"1000px"}
                    width={"1000px"}
                    src={require("../img/Zappos_ONE.png")}
                    alt=""
                  />
                </div>
                <div className="p-3 text-center">
                  <h5>Diversity Equity & Inclusion</h5>
                  <p>
                    At Zappos, diversity and individuality are central to our
                    core values.
                  </p>
                  <a href="/" className="text-dark">
                    {" "}
                    Learn More About Zappos ONE Purpose
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    height={"1000px"}
                    width={"1000px"}
                    src={require("../img/zappos_two.jpg")}
                    alt=""
                  />
                </div>
                <div className="p-3 text-center">
                  <h5> Zappos for Good</h5>
                  <p>
                    Learn how to easily donate or recycle your secondhand items.
                  </p>
                  <a href="/" className="text-dark">
                    Explore Zappos for Good
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <img
                    className="img-fluid pb-2"
                    height={"1000px"}
                    width={"1000px"}
                    src={require("../img/zappos_thre.png")}
                    alt=""
                  />
                </div>
                <div className="p-3 text-center">
                  <h5>Goods for Good</h5>
                  <p>
                    We're highlighting brands who are making a difference and
                    helping build a better place for us all.
                  </p>
                  <a href="/" className="text-dark">
                    Shop Goods for Good
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home_;
