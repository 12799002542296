import React from "react";

const Error = () => {
  return (
    <>
      <div className="bg-light text-center " style={{ height: "50vh" }}>
        <div className="container">
          <div className="row error-container">
            <div className="col-md-12 text-center">
              <h1 className="error-heading">404</h1>
              <p className="error-text">
                Oops! The page you are looking for could not be found.
              </p>
              <a
                className="text-decoration-none btn btn-lg btn-back fs-2"
                href="/"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
