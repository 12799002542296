import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const First_slide = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1428 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1428, min: 997 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 997, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="container-fluid pb-4 ">
        <div className="container ">
          <Carousel responsive={responsive}>
            <div
              className="d-flex flex-column justify-content-center align-items-lg-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-1.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-3.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-4.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-5.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-3.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-4.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-5.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-start px-2"
              style={{
                height: "700px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "450px",
                  width: "100%",
                }}
              >
                <span
                  className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                  style={{
                    border: "0.1px solid black",
                  }}
                >
                  <i class="bi bi-heart" style={{ fontSize: "15px" }}></i>
                </span>{" "}
                <img
                  className="img-fluid p-3 pt-0"
                  src={require("../img/ts-1.png")}
                  alt=""
                />
              </div>

              <div className="pt-3">
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i11.webp")}
                    alt=""
                  />
                </span>
                <span>
                  {" "}
                  <img
                    className="img-fluid rounded-circle"
                    style={{ height: "34px", width: "34px" }}
                    src={require("../img/i12.webp")}
                    alt=""
                  />
                </span>
                <br></br>
                <span className="fw-bold">UGG</span>
                <br></br>
                <span>Scuffette || Water-Resistant Slipper</span>
                <br></br>
                <span className="fw-bold">$94.95</span>
                <br></br>
                <span>
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>{" "}
                  <i class="bi bi-star-fill" style={{ color: "blue" }}>
                    {" "}
                  </i>
                  (4347)
                </span>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default First_slide;
