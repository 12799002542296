import React from "react";

const Sale = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="container-lg p-0">
            <div className="row">
              <div className="col-md-2">
                <h2>Women's Products</h2>
                <p>33297 items found Find what you're looking for?</p>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item ">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button "
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Gender
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                    >
                      <div class="accordion-body">
                        <p>Women</p>
                        <p>Men</p>
                        <p>Girls</p>
                        <p>Boys</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Product Type
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p>Shoes</p>
                        <p>Clothing</p>
                        <p>Bags</p>
                        <p>Accessories</p>
                        <p>Jewelry</p>
                        <p>Eyewear</p>
                        <p>Watches</p>
                        <p>Sporting Goods</p>
                        <p>Home</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Brands
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p>#4kids</p>
                        <p>1.STATE</p>
                        <p>2XU</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Price
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p>$50.00 and Under</p>
                        <p>$100.00 and Under</p>
                        <p>$200.00 and Under</p>
                        <p>$200.00 and Over</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Color
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p>Black</p>
                        <p>Blue</p>
                        <p>White</p>
                        <p>Brown</p>
                        <p>Gray</p>
                        <p>Multi</p>
                        <p>Navy</p>
                        <p>Pink</p>
                        <p>Green</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                {/* <div className="row">
                  <div className="col-md-6">
                    <h3>Your Selections</h3>
                  </div>
                  <div className="col-md-6">
                    <div class="dropdown float-end">
                      <button
                        class="btn btn-outline-primary rounded-pill text-black dropdown-toggle bg-white px-4"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        New Arrivals
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <button class="dropdown-item" type="button">
                            Relevance
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" type="button">
                            New Arrivals
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" type="button">
                            Customer Ratings
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" type="button">
                            Best Sellers
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" type="button">
                            Price:low to high
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" type="button">
                            Price:high to low
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="row g-0">
                  <div className="col-lg-3 col-md-6 ">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid "
                          src={require("../img/sale7.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale2.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale11.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale7.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-5.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-4.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-1.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale11.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-4.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale9.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-5.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-4.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale12.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale7.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-4.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale2.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale5.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale7.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale6.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale1.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/sale7.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-start px-2"
                      style={{
                        height: "700px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f5f5f5",
                          height: "450px",
                          width: "100%",
                        }}
                      >
                        <span
                          className="float-start rounded-end-pill bg-white mt-4 px-2  border-start-0 py-1 "
                          style={{
                            border: "0.1px solid black",
                            boxShadow: "3px 3px  1px yellow",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          New
                        </span>
                        <span
                          className="float-end rounded-pill bg-white mt-4 mx-4 px-3 py-1"
                          style={{
                            border: "0.1px solid black",
                          }}
                        >
                          <i
                            class="bi bi-heart"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </span>
                        <img
                          className="img-fluid p-3 pt-0"
                          src={require("../img/ts-3.png")}
                          alt=""
                        />
                      </div>

                      <div className="pt-3">
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i11.webp")}
                            alt=""
                          />
                        </span>
                        <span>
                          <img
                            className="img-fluid rounded-circle"
                            style={{ height: "34px", width: "34px" }}
                            src={require("../img/i12.webp")}
                            alt=""
                          />
                        </span>
                        <br></br>
                        <span className="fw-bold">UGG</span>
                        <br></br>
                        <span>Scuffette || Water-Resistant Slipper</span>
                        <br></br>
                        <span className="fw-bold">$94.95</span>
                        <br></br>
                        <span>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          <i
                            class="bi bi-star-fill"
                            style={{ color: "blue" }}
                          ></i>
                          (4347)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sale;
