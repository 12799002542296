import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const First_slide = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1428 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1428, min: 997 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 997, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="container-fluid pb-4 ">
        <div className="container">
          <Carousel responsive={responsive}>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "7px",
                }}
              >
                <img
                  className="img-fluid  text-center"
                  src={require("../img/s11.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Sneaker Clearance
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                }}
              >
                {" "}
                <img
                  className="img-fluid mx-auto d-block"
                  style={{ height: "250px" }}
                  src={require("../img/s22.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Coat & Outerwear Clearance{" "}
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid  text-center"
                  src={require("../img/s33.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Boot Clearance
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                }}
              >
                {" "}
                <img
                  className="img-fluid mx-auto d-block"
                  style={{ height: "250px" }}
                  src={require("../img/s44.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Womens's Clearance{" "}
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                }}
              >
                {" "}
                <img
                  className="img-fluid mx-auto d-block"
                  style={{ height: "250px" }}
                  src={require("../img/s55.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Mens's Clearance{" "}
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid  text-center"
                  src={require("../img/s66.png")}
                  alt=""
                />
              </div>

              <a
                href="/"
                style={{
                  color: "#000000",
                  fontWeight: "600",
                  paddingTop: "8px",
                }}
              >
                Sleepar Clearance
              </a>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default First_slide;
