import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Second_slide = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1428 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1428, min: 997 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 997, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="container-fluid pb-4 ">
        <div className="container">
          <Carousel responsive={responsive}>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a1.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                Hunter
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "25px 7px 5px 7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a2.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                UGG®{" "}
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "60px 7px 1px 7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a3.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                Clarks
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "60px 7px 1px 7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a4.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                Teva
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a5.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                Blundstone{" "}
              </a>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "250px",
                  width: "300px",
                  padding: "50px 7px 1px 7px",
                }}
              >
                {" "}
                <img
                  className="img-fluid pb-2 text-center"
                  src={require("../img/a6.png")}
                  alt=""
                />
              </div>

              <a href="/" style={{ color: "#000000", fontWeight: "600" }}>
                ECCO{" "}
              </a>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Second_slide;
